.i_w {
    padding: 16px 24px;
    max-width: 1420px;
    margin: 0 auto;
    user-select: none;
}

.center {
    text-align: center;
    min-height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

crowdin-card {
    background: #fff;
}